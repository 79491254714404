import { getLogger } from './logger';
import { DimensionValue } from '../interfaces/logger';

const getTimingMetrics = (): PerformanceNavigationTiming | null => {
	if (window?.performance?.getEntriesByType) {
		const entries = window.performance.getEntriesByType('navigation');

		if (entries?.length === 1) {
			return entries[0] as PerformanceNavigationTiming;
		} else {
			getLogger().error(
				`Multiple navigation timing instances in user agent ${navigator.userAgent}`,
			);
			return null;
		}
	} else {
		getLogger().error(
			`window.performance.getEntriesByType is unavailable in user agent ${navigator.userAgent}`,
		);
		getLogger().addPerformanceListener();
		return null;
	}
};

const captureOnLoadMetrics = (publishMetric: Function) => {
	const metrics = getTimingMetrics();

	// Early exit if timing metrics are unavailable.
	if (!metrics) return;

	publishMetric('DomInteractive', metrics.domInteractive);
	publishMetric(
		'DomContentLoadedEventStart',
		metrics.domContentLoadedEventStart,
	);
	publishMetric('DomComplete', metrics.domComplete);
	publishMetric('LoadEventStart', metrics.loadEventStart);
	publishMetric('FetchStart', metrics.fetchStart);
	publishMetric('TTFB', getTTFB(metrics));
	publishMetric('DNS', getDNS(metrics));
	publishMetric('Latency', getLatency(metrics));
	publishMetric('UiTime', getUiTime(metrics));
};

const loadEventFinished = () => {
	const metrics = getTimingMetrics();
	return !!metrics?.loadEventEnd;
};

const publishTimerMetric = (metricName: string, metricValue: number) => {
	getLogger().timerMetric({
		metricName,
		metricValue,
		dimensionValue: DimensionValue.Performance,
	});
};

export const captureTimingMetrics = (
	publishMetric: (
		metricName: string,
		metricValue: number,
	) => void = publishTimerMetric,
) => {
	if (loadEventFinished()) {
		captureOnLoadMetrics(publishMetric);
	} else {
		window.addEventListener('load', () => {
			// Defer to after the load event has finished.
			setTimeout(() => captureOnLoadMetrics(publishMetric));
		});
	}
};

const getTTFB = (metrics: PerformanceNavigationTiming) =>
	metrics.responseStart - metrics.requestStart;

const getDNS = (metrics: PerformanceNavigationTiming) =>
	metrics.domainLookupEnd - metrics.domainLookupStart;

const getLatency = (metrics: PerformanceNavigationTiming) =>
	metrics.responseEnd - metrics.fetchStart;

const getUiTime = (metrics: PerformanceNavigationTiming) =>
	metrics.loadEventStart - metrics.responseStart;
