import { defineMessages } from 'react-intl';

const message = defineMessages({
	errorPage404ErrorMessage: {
		id: 'errorPage.404ErrorMessage',
		defaultMessage: '404 Page Not Found',
	},
});

export default message;
