import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Switch } from 'react-router-dom';

import { Path } from '../../constants/path';
import RouteWithErrorBoundary from './RouteWithErrorBoundary';
import message from './Routes.messages';
import NotFoundPage from '../../pages/NotFoundPage';
import QuickSightPage from '../../pages/QuickSightPage';

const Routes = () => {
	const { formatMessage } = useIntl();

	const routes = [
		{
			exact: true,
			path: Path.Home,
			children: <Redirect to={Path.QuickSight} />,
		},
		{
			exact: true,
			path: Path.QuickSight,
			children: <QuickSightPage />,
		},
		// Default 404 page
		{
			exact: undefined,
			path: undefined,
			children: (
				<NotFoundPage
					children={formatMessage(message.errorPage404ErrorMessage)}
				/>
			),
		},
	];

	return (
		<Switch>
			{routes.map(({ exact, path, children }, index) => (
				<RouteWithErrorBoundary
					key={index}
					exact={exact}
					path={path}
					children={children}
				/>
			))}
		</Switch>
	);
};

export default Routes;
