import { defineMessages } from 'react-intl';

const message = defineMessages({
	headerLogoText: {
		id: 'header.logoText',
		defaultMessage: 'aws training and certification',
	},
});

export default message;
