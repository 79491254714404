import { ApolloClient, NormalizedCacheObject, ApolloProvider } from "@apollo/client";
import { useState, useEffect, ReactNode } from "react";
import { getApolloClient } from "../client/apolloClient";
 
export const ApolloClientProvider: React.FC<{
  children: React.ReactNode; }> = ({
    children,}: {children: ReactNode;
  }) => {
    const [applloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  
    useEffect(() => {
      getApolloClient().then(setApolloClient).catch(console.error);
    }, []);
  
    if (!applloClient) {
      return <div data-testid="loading">Loading Apollo Client...</div>;
    } else {
      return (
        <ApolloProvider client={applloClient}>
          <div data-testid="apollo-provider">{children}</div>
        </ApolloProvider>
      );
    }
};