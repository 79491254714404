import '@amzn/awsui-global-styles/polaris.css';

import { createLogger } from './utils/logger';
import { DimensionValue } from './interfaces/logger';
import { querySettings } from './utils/queryUtils';
import { configureAuth } from './utils/auth';
import { captureTimingMetrics } from './utils/performance';
import {
	logRootLevelError,
	renderApplication,
	renderErrorPage,
	renderLastResortErrorPage,
} from './utils/appRootUtils';

const $root = document.getElementById('root') as HTMLElement;
if ($root != null) {
	Promise.resolve({})
		// Get configuration and feature flags
		.then(querySettings)
		// Create logger instance, capture performance metrics, and configure client-side authentication
		.then((settings) => {
			const logger = createLogger({
				loggingApiDomain: settings.log.domain,
				host: document.location.host,
			});
			logger.counterMetric({
				metricName: 'Session',
				dimensionValue: DimensionValue.Traffic,
			});
			captureTimingMetrics();
			configureAuth(settings);
		})
		// Render the application
		.then(() => renderApplication($root))
		// Catch and log root level error, and rethrow it to the next processing layer
		.catch((error) => {
			logRootLevelError(error);
			throw error;
		})
		// Try to render the error page with similar contexts of the application
		.catch(() => renderErrorPage($root))
		// If failed, render the last resort error page with minimum contexts
		.catch(() => renderLastResortErrorPage($root));
}
