import handleQuickSightApiRequest, {
	QuickSightUrl,
} from '../utils/handleQuickSightApiRequest';

import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import { HandleRequestData } from '../interfaces/handleRequest';

export const PATH = 'report';

const getUrl = (): Promise<HandleRequestData<QuickSightUrl>> => {
	const startTime = new Date().getTime();

	return handleQuickSightApiRequest<QuickSightUrl>(
		PATH,
		{
			method: 'GET',
		},
		{
			onSuccess: () => {
				const endTime = new Date().getTime();
				getLogger().timerMetric({
					metricName: 'GetQuickSightApiLatency',
					metricValue: endTime - startTime,
					dimensionValue: DimensionValue.Latency,
				});
			},
		},
	);
};

const quicksightUrlApi = {
	getUrl,
};
export default quicksightUrlApi;
