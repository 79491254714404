export type Dimension = {
	key: string;
	value: string;
};

export enum DimensionValue {
	Error = 'Error',
	Traffic = 'Traffic',
	Latency = 'Latency',
	Analysis = 'Analysis',
	Performance = 'Performance',
	Test = 'Test',
}

export enum Unit {
	Seconds = 'Seconds',
	Microseconds = 'Microseconds',
	Milliseconds = 'Milliseconds',
	Percent = 'Percent',
	Count = 'Count',
	None = 'None',
}

export type Metric = {
	name: string;
	value: number;
	unit?: Unit | string;
};

export type EMFMetric = {
	emfLog: {
		_aws: {
			Timestamp: number;
			CloudWatchMetrics: Array<{
				Namespace: string;
				Metrics: Array<{ Name: string; Unit?: string }>;
				Dimensions?: Array<Array<string>>;
			}>;
		};
	};
};
