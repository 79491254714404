import { defineMessages } from 'react-intl';

const message = defineMessages({
	quickSightPageHeader: {
		id: 'quickSight.pageHeader',
		defaultMessage: 'Reports',
	},
	returnToDashboard: {
		id: 'quickSight.returnToDashboard',
		defaultMessage: 'Return to administrator dashboard',
	},
});

export default message;
