import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundary from '../ErrorBoundary';

const RouteWithErrorBoundary: React.FC<RouteProps> = (props: RouteProps) => {
	useEffect(() => {
		if (window?.AWSMA) {
			window.AWSMA.ready(() => {
				document.dispatchEvent(
					new CustomEvent(window.AWSMA.TRIGGER_EVENT, {
						detail: {
							page: {
								pageURL: `${window.location.href}`,
							},
							event: {
								type: 'pageview',
								name: 'aws-docebosaas.com',
							},
							data: {},
						},
					}),
				);
			});
		}
	}, [props.location]);

	return (
		<ErrorBoundary isOutsideRouter={false} key={props.location?.pathname}>
			<Route {...props} />
		</ErrorBoundary>
	);
};

export default RouteWithErrorBoundary;
