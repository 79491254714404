import {  useEffect, useState } from 'react';
import axios from 'axios';
import { getCurrentUser } from '../utils/auth';
import { querySettings } from '../utils/queryUtils';
import { getLogger } from '../utils/logger';

export type EvaluateFeatureHook = (props: {
  fallback: boolean;
}) => [boolean, boolean];

let evidentlyUrl: string = '';

export const useEvaluateFeature: EvaluateFeatureHook = ({ fallback }) => {
    const [returnValue, setReturnValue] = useState<ReturnType<EvaluateFeatureHook>>([fallback, true]);
    const logger = getLogger();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authUser = await getCurrentUser();
                const entityId = authUser.vibeId;
                const response = await evaluateFeature({ entityId });
                const val = handleResponse(response, fallback, logger);
                setReturnValue([val, false]);
            } catch (error) {
                logger.error(`Failed to evaluate feature: SuperGraph and project: SBAIRLaunches with error: ${error}`);
                setReturnValue([fallback, false]);
            }
        };

        fetchData();
    }, [fallback, logger]);

    return returnValue;
};

const handleResponse = (response: { data: any; loading: boolean; }, fallback: boolean, logger: any) => {
    if (response?.data?.value?.boolValue === undefined) {
        logger.error("Evaluated featured did not return the expected type", response);
        return fallback;
    }
    return response?.data?.value?.boolValue;
};

export const evaluateFeature = async ({
    entityId,
}: { entityId: string }): Promise<{ data: any; loading: boolean; }> => {
    if (!evidentlyUrl) {
        const settings = await querySettings();
        evidentlyUrl = settings?.evidently?.url;
    }

    const response = await axios.post(evidentlyUrl, { entityId });
    return { data: response?.data, loading: false };
};