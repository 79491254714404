import React, { Component, ErrorInfo, ReactNode } from 'react';

import ErrorPage, { ErrorPageWithFullLayout } from '../../pages/ErrorPage';
import { getLogger } from '../../utils/logger';
import { DimensionValue } from '../../interfaces/logger';

interface ErrorBoundaryProps {
	children: ReactNode;
	isOutsideRouter: boolean;
}

interface ErrorBoundaryState {
	hasError: boolean;
	errorName?: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, errorName: _.name };
	}

	// eslint-disable-next-line class-methods-use-this
	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const logger = getLogger();
		logger.error('ErrorBoundary', error, errorInfo);
		logger.counterMetric({
			metricName: 'ErrorBoundaryError',
			dimensionValue: DimensionValue.Error,
		});
	}

	render() {
		const isOutsideRouter = this.props.isOutsideRouter;
		if (this.state.hasError) {
			if (isOutsideRouter) {
				return (
					<ErrorPageWithFullLayout errorName={this.state.errorName} />
				);
			} else {
				return <ErrorPage errorName={this.state.errorName} />;
			}
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
