import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { Box, Button } from '@amzn/awsui-components-react';
import './styles.scss';
import { errorCopy } from '../../../constants/common';
import useSettings from '../../../hooks/useSettings';
import { analyticsClickEvent } from '../../../analytics';

const Error = (props: any) => {
	const { settings } = useSettings();
	const { errorName } = props;

	return (
		<div className="error-div-center" data-testid="error-body">
			<SpaceBetween size="xxs">
				<div className="icon-center">
					<img src="/error.svg" alt={'Error Icon'} id="error-icon" />
				</div>
				<div>
					<Box
						variant="h1"
						textAlign="center"
						className="heading-text"
						id="error-header-text-box"
					>
						<div
							className="heading-text"
							data-testid="error-header-text"
						>
							{errorCopy(errorName).headerText}
						</div>
					</Box>
				</div>
				<div>
					<Box
						variant="p"
						textAlign="center"
						id="error-description-box"
						data-testid="error-description-text"
					>
						{errorCopy(errorName).descriptionText}
					</Box>
				</div>
				<div className="button-center">
					<Button
						variant="primary"
						href={errorCopy(errorName).buttonUrl(settings)}
						data-testid="btn-error-cta"
						onClick={analyticsClickEvent(['btn', 'Error Page CTA'])}
					>
						{errorCopy(errorName).buttonText}
					</Button>
				</div>
			</SpaceBetween>
		</div>
	);
};
export default Error;
