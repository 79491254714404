import { useEffect } from 'react';
import { useAdminReportUrlContext } from './AdminReportUrlContextProvider';
import { useEvaluateFeature } from '../hooks/useEvaluateFeature';
import React from 'react';
import { ApolloError } from '@apollo/client';
import useAdminReport, { AdminReportData } from '../hooks/useAdminReport';
import { useAuthContext } from './AuthContextProvider';

const QuickSightUrlManager: React.FC<{
	children: React.ReactNode;}> = ({ children }: { children: React.ReactNode; }) => {
  const { getUrl, handleAdminReportData } = useAdminReportUrlContext();
  const { authState } = useAuthContext();
  const [callSupergraph, isFeatureFlagLoading] = useEvaluateFeature({
    fallback: false,
  });

  const { data, loading, error }: { data: AdminReportData, loading: boolean, error?: ApolloError } = useAdminReport(callSupergraph, authState.isAuthenticated);

  useEffect(() => {
    if (!isFeatureFlagLoading) {      
        if (callSupergraph) {
            handleAdminReportData(data, loading, error);
        } else {
          getUrl();
        }
    } 
  }, [callSupergraph, isFeatureFlagLoading, getUrl, loading, data, error, handleAdminReportData]);

  return <>{children}</>;
};

export default QuickSightUrlManager;