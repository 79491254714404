import { BifrostSettings } from '../interfaces/bifrostSettings';
import handleRequest from '../utils/handleRequest';

/**
 * Retrieve configuration from settings.json
 * @throws {Error} Failed to fetch settings.json
 */
export const getSettings = async (): Promise<BifrostSettings> => {
	try {
		const response = await handleRequest<BifrostSettings>(
			'/settings.json',
			{
				method: 'GET',
			},
		);
		return response.result;
	} catch (error) {
		throw new Error('Failed to fetch settings.json');
	}
};
