import { getAuthUserToken } from './auth';
import { RetryOptions } from './retry';
import { querySettings } from './queryUtils';
import handleRequest from './handleRequest';
import { HandleRequestData } from '../interfaces/handleRequest';

let quicksightApiDomain: string = '';
export interface QuickSightUrl {
	EmbedUrl: string;
}

const handleQuickSightApiRequest = async <T = any>(
	path: string,
	fetchOptions: RequestInit,
	retryOptions?: Partial<RetryOptions>,
): Promise<HandleRequestData<QuickSightUrl>> => {
	const token = await getAuthUserToken();
	const requestOptions: RequestInit = {
		...fetchOptions,
		headers: {
			...fetchOptions.headers,
			Authorization: `Bearer ${token}`,
		},
	};

	if (!quicksightApiDomain) {
		const settings = await querySettings();
		quicksightApiDomain = settings?.quicksight?.domain;
	}

	return handleRequest<QuickSightUrl>(
		`https://${quicksightApiDomain}/${path}`,
		requestOptions,
		retryOptions,
	);
};

export default handleQuickSightApiRequest;
