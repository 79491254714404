import React, { useEffect } from 'react';

import ErrorMessage from './Common/ErrorMessage';
import { getLogger } from '../../utils/logger';
import { DimensionValue } from '../../interfaces/logger';

const ErrorPage = (props: any) => {
	const logger = getLogger();
	const { errorName } = props;

	useEffect(() => {
		logger.counterMetric({
			metricName: 'ErrorPage',
			dimensionValue: DimensionValue.Traffic,
		});
	}, [logger]);

	return (
		<div data-testid="ErrorPage">
			<ErrorMessage errorName={errorName} />
		</div>
	);
};

export default ErrorPage;
