import { defineMessages } from 'react-intl';

const message = defineMessages({
	footerPrivacyLink: {
		id: 'footer.privacyLink',
		defaultMessage: 'Privacy',
	},
	footerSiteTermsLink: {
		id: 'footer.siteTermsLink',
		defaultMessage: 'Site terms',
	},
	footerCopyrightInformation: {
		id: 'footer.copyrightInformation',
		defaultMessage:
			'© {year}, Amazon Web Services, Inc. or its affiliates. All rights reserved.',
	},
});

export default message;
