import React from 'react';
import {
	Alert as PolarisAlert,
	AlertProps as PolarisAlertProps,
} from '@amzn/awsui-components-react';

import { ERROR_STRING } from '../../constants/common';

export interface AlertProps {
	children?: string | JSX.Element;
	header?: string;
	type?: PolarisAlertProps.Type;
	dismissAriaLabel?: string;
	dismissible?: boolean;
	onDismiss?: (event: any) => void;
}

const Alert = ({
	children = ERROR_STRING.NOT_FOUND,
	header = 'Error',
	type = 'error',
	dismissAriaLabel = 'Close alert',
	dismissible,
	onDismiss = () => {},
}: AlertProps) => (
	<PolarisAlert
		header={header}
		type={type}
		data-testid="Alert"
		dismissAriaLabel={dismissAriaLabel}
		dismissible={dismissible}
		onDismiss={onDismiss}
	>
		{children}
	</PolarisAlert>
);

export default Alert;
