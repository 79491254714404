import React from 'react';
import { useIntl } from 'react-intl';
import { Link, TextContent } from '@amzn/awsui-components-react';

import './Footer.scss';
import message from './Footer.messages';

const Footer = () => {
	const { formatMessage } = useIntl();

	return (
		<div className="footer-wrapper" data-testid="Footer">
			<footer className="bifrost-footer">
				<Link
					href="https://aws.amazon.com/privacy/?src=aws_training"
					className="bifrost-footer-link"
					data-testid="Footer_Privacy"
					ariaLabel="Navigate to AWS Privacy Notice"
				>
					{formatMessage(message.footerPrivacyLink)}
				</Link>
				<div className="separator"></div>
				<Link
					href="https://aws.amazon.com/terms/?nc1=f_pr"
					className="bifrost-footer-link"
					data-testid="Footer_SiteTerms"
					ariaLabel="Navigate to AWS Site Terms"
				>
					{formatMessage(message.footerSiteTermsLink)}
				</Link>
				<div className="separator"></div>
				<TextContent className="text-align-center">
					{formatMessage(message.footerCopyrightInformation, {
						year: 2021,
					})}
				</TextContent>
			</footer>
		</div>
	);
};

export default Footer;
