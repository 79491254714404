import { gql, useQuery } from "@apollo/client";

interface AdminReport {
  reportEmbeddingUrl: string;
  reportNotifications: ReportNotification[];
}

interface ReportNotification {
  reportNotificationCode: string;
  reportNotificationMessage: string;
}

export interface AdminReportData {
  adminReport: AdminReport;
}

export const ADMIN_REPORT_QUERY = gql`
  query MyQuery {
    adminReport {
      reportEmbeddingUrl
      reportNotifications {
          reportNotificationCode
          reportNotificationMessage
      }
    }
  }
`;

const useAdminReport = (callSupergraph: boolean, isAuthenticated: boolean) => {  
  const {data, loading, error} = useQuery(ADMIN_REPORT_QUERY, {
    variables: { errorPolicy: 'all' },
    // skip the call if callSupergraph flag is false or user is not authenticated
    skip: (!callSupergraph || !isAuthenticated),
  });
  return { data, loading, error };
};

export default useAdminReport;

