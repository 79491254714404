import { useQuery } from 'react-query';

import { BifrostSettings } from '../interfaces/bifrostSettings';
import { getSettings } from '../utils/fetchSettings';
import { SETTINGS_QUERY_KEY } from '../constants/queryKey';

export default function useSettings() {
	const {
		data: settings,
		error: settingsError,
		isLoading: settingsIsLoading,
		status: settingsStatus,
		...others
	} = useQuery<BifrostSettings, Error>(SETTINGS_QUERY_KEY, getSettings, {
		staleTime: Infinity,
	});

	// immutably rename these keys to settings-specific names
	return {
		settings,
		settingsError,
		settingsIsLoading,
		settingsStatus,
		...others,
	};
}
