import React, { useEffect } from 'react';

import { ERROR_STRING } from '../../constants/common';
import Alert, { AlertProps } from '../../components/Alert';
import { getLogger } from '../../utils/logger';
import { DimensionValue } from '../../interfaces/logger';

export type NotFoundPageProps = AlertProps;

const NotFoundPage = ({
	children = ERROR_STRING.NOT_FOUND,
	header = 'Error',
	type = 'error',
}: NotFoundPageProps) => {
	const logger = getLogger();

	useEffect(() => {
		logger.counterMetric({
			metricName: 'NotFoundPage',
			dimensionValue: DimensionValue.Traffic,
		});
	}, [logger]);

	return (
		<div data-testid="NotFoundPage">
			<Alert header={header} type={type} children={children} />
		</div>
	);
};

export default NotFoundPage;
