import React, { useEffect } from 'react';
import { AppLayout } from '@amzn/awsui-components-react';
import { MAX_WIDTH } from '../../constants/common';
import ErrorMessage from './Common/ErrorMessage';
import Footer from '../../components/Footer';
import { getLogger } from '../../utils/logger';
import { DimensionValue } from '../../interfaces/logger';

// rendered in App.tsx outside router
const ErrorPageWithFullLayout = (props: any) => {
	const logger = getLogger();
	const { errorName } = props;

	useEffect(() => {
		logger.counterMetric({
			metricName: 'ErrorPageWithFullLayout',
			dimensionValue: DimensionValue.Traffic,
		});
	}, [logger]);

	return (
		<div className="awsui" data-testid="ErrorPageWithFullLayout">
			<AppLayout
				content={
					<div
						id="js-main-content"
						tabIndex={-1}
						style={{ outline: 'none' }}
					>
						<ErrorMessage errorName={errorName} />
					</div>
				}
				navigationHide={true}
				toolsHide={true}
				maxContentWidth={MAX_WIDTH}
				headerSelector=".header-wrapper"
			/>
			<Footer />
		</div>
	);
};

export default ErrorPageWithFullLayout;
