import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout, Box, StatusIndicator } from '@amzn/awsui-components-react';

import Routes from '../Routes';
import ErrorPage from '../../pages/ErrorPage';
import Footer from '../Footer';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import './App.scss';
import Header from '../Header';
import { useIdleTimer } from 'react-idle-timer';
import { Auth } from '@aws-amplify/auth';

const App = () => {
	const { authState } = useAuthContext();
	const { authError, authUser } = authState;
	let appContent;

	const onAction = () => {
		Auth.currentSession();
	};

	const onIdle = () => {
		Auth.signOut();
	};

	useIdleTimer({
		onIdle,
		onAction,
		timeout: 1000 * 60 * 75, // 75 mins
	});

	if (authError) {
		appContent = <ErrorPage />;
	} else if (!authUser) {
		appContent = (
			<Box textAlign="center">
				<StatusIndicator type="loading">{'Loading'}</StatusIndicator>
			</Box>
		);
	} else {
		appContent = <Routes />;
	}
	return (
		<Router>
			<div className="awsui" data-testid="App">
				<Header />
				<AppLayout
					content={
						<div id="js-main-content" tabIndex={-1}>
							{appContent}
						</div>
					}
					navigationHide={true}
					toolsHide={true}
					headerSelector=".header-wrapper"
					footerSelector=".footer-wrapper"
				/>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
