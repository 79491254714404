import { QueryClient, FetchQueryOptions } from 'react-query';

import { BifrostSettings } from '../interfaces/bifrostSettings';
import { SETTINGS_QUERY_KEY } from '../constants/queryKey';
import { getSettings } from './fetchSettings';

const queryClient = new QueryClient();

export const querySettings = (
	options: FetchQueryOptions<BifrostSettings, Error> = {
		staleTime: Infinity,
	},
) => {
	const response = queryClient.fetchQuery<BifrostSettings, Error>(
		SETTINGS_QUERY_KEY,
		getSettings,
		options,
	);
	return response;
};

export default queryClient;
