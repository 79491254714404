import React from 'react';
import { useIntl } from 'react-intl';

import message from './Header.messages';

export const headerBrandingSelector = 'header__branding';

export const HeaderLogoImg = ({ mobile }: { mobile: boolean }) => {
	const { formatMessage } = useIntl();

	return (
		<img
			src="/aws-tc-logo.svg"
			alt={formatMessage(message.headerLogoText)}
			data-testid={`HeaderLogo${mobile ? 'Mobile' : 'Desktop'}`}
		/>
	);
};
